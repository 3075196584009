import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEOPage from '../components/SEO/Page'

const NotFoundPage = ({ location }) => (
  <Layout>
    <SEOPage title="404 page" location={location} />
    <div className="height-404 relative">
      <div className="absolute center-xy text-grey text-center">
        <h2 className="mb-8">Oops, something went wrong..</h2>
        <p>
          Would you like to{' '}
          <Link className="link underline font-bold" to="/">
            go home?
          </Link>
          .
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
